import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

  constructor(private http: HttpClient) { }

  getallData(url) {
    return this.http.get(url);
  }
  getData(url, id) {
    return this.http.get(url + `?id=${id}`);
  }
  postData(url, data) {
    return this.http.post(url, data);
  }
  deleteData(url, id) {
    return this.http.delete(url + `?id=${id}`);
  }
  putData(url, data) {
    return this.http.put(url, data);
  }
  //claim data
  getDataClaim(url, vendorid, id) {
    return this.http.get(url + `?vendorid=${vendorid}&id=${id}`);
  }
}

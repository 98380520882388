import { AddEditData, UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { v4 as uuidv4 } from "uuid";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';
import { InvoiceGeneratorService } from "src/app/invoice-generator.service";
@Component({
  selector: "app-transaction-details",
  templateUrl: "./transaction-details.component.html",
  styleUrls: ["./transaction-details.component.css"],
})
export class TransactionDetailsComponent implements OnInit, AddEditData {
  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything
  // select this appropriately
  section = this.utility.apiData.purchases;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];
  email = null;
  id = null;
  date = Date.now();
  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location,
    private invoice: InvoiceGeneratorService
  ) { }
  @ViewChild("mainForm", { static: false }) mainForm: NgForm;
  ngOnInit() {
    this.resetForm();
    this.hasData();
  }
  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = JSON.parse(JSON.stringify(this.section.object));
  }
  loadData(id) { }
  loadDatFinance(email, id) {
    //Get Form Data via API
    this.dataService.getallData(this.section.ApiUrl + `?email=${email}&id=${id}`).subscribe(
      (Response) => {
        console.log(this.utility.dovValidateSchema.validate(Response, this.section.schema))
        if (!this.utility.dovValidateSchema.validate(Response, this.section.schema).valid) {
          swal("No data exists");
          this.location.back();
        }
        this.object = Response;
        this.isLoadingData = false;
      },
      (error) => {
        swal("No data exists");
        this.location.back();
      });
  }
  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") && params.get("id") != "" && params.get("email") && params.get("email") != "") {
        this.isLoadingData = true;
        this.isEditMode = true;
        this.email = params.get("email");
        this.id = params.get("id");
        this.loadDatFinance(params.get("email"), params.get("id"));
      }
    });
  }

  //write part

  loadInvoice() {
    this.invoice.loadData(this.email, this.id);
  }

  recieptFile = null;
  uploadBinaryData() {
    this.recieptFile = ""; //only in cases where there is singular binding
    let requests = this.binaryFiles.map((object) => {
      return this.utility.uploadBinaryData(object["name"], object["binaryData"], this.section.bucket);
    });
    Promise.all(requests)
      .then((responses) => {
        this.recieptFile = responses[0]["name"];
        this.uploadFormData();
      })
      .catch((error) => {
        console.log(error);
        this.isUploadingData = false;
      });
  }
  uploadFormData() {
    let json: JSON = this.mainForm.value;
    json['paymentDate'] = new Date(json['paymentDate']).getTime();
    json['claimID'] = this.object.dependencies.claims.claimID;
    json['vendorID'] = this.object.vendorID;
    json['receiptUpload'] = this.recieptFile;
    //post request here,both add & update are sent as post
    this.dataService
      .postData(this.utility.apiData.vendorClaims.ApiUrl, JSON.stringify(json))
      .subscribe((Response) => {
        console.log(Response);
        swal("Claim Settled succesfully");
        this.isUploadingData = false;
        this.location.back();
      }, (error) => {
        swal("Failed to process request,please try again");
        console.log(error);
        this.isUploadingData = false;
      });
  }
  loadBinaryFile(event) {
    if (event.target.files.length > 0) {
      this.binaryFiles = [
        { name: uuidv4(), binaryData: event.target.files[0] },
      ];
    }
  }
  onSubmit() {
    if (this.mainForm.invalid) {
      this.mainForm.form.markAllAsTouched();
      swal("Please enter values in the highlighted fields");
      return false;
    }
    this.isUploadingData = true;

    if (this.binaryFiles.length > 0) {
      this.uploadBinaryData();
    } else {
      this.uploadFormData();
    }
  }
}

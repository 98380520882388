import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddproductComponent } from './products/addproduct/addproduct.component';
import { MainComponent } from './main/main.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { HttpClientModule } from '@angular/common/http';
import { PaymentComponent } from './useraccounts/payment/payment.component';
import { AddpackageComponent } from './packages/addpackage/addpackage.component';
import { PackageListComponent } from './packages/package-list/package-list.component';
import { AddvendorComponent } from './vendors/addvendor/addvendor.component';
import { VendorListComponent } from './vendors/vendor-list/vendor-list.component';
import { AddrolesComponent } from './roles/addroles/addroles.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { SubAdminListComponent } from './sub-admin/sub-admin-list/sub-admin-list.component';
import { AddSubAdminComponent } from './sub-admin/add-sub-admin/add-sub-admin.component';
import { TransactionListComponent } from './finance/transaction-list/transaction-list.component';
import { TransactionDetailsComponent } from './finance/transaction-details/transaction-details.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { AddenterpriseComponent } from './enteprie/addenterprise/addenterprise.component';
import { ListenterpriseComponent } from './enteprie/listenterprise/listenterprise.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { EtrCustomerListComponent } from './enteprie/customer/customer-list/customer-list.component';
import { EtrCustomerdetailComponent } from './enteprie/customer/customerdetail/customerdetail.component';
import { AddinvoiceComponent } from './enteprie/invoices/addinvoice/addinvoice.component';
import { ListinvoicesComponent } from './enteprie/invoices/listinvoices/listinvoices.component';
import { DatePipe } from '@angular/common';
import { TrailPackageComponent } from './packages/trail-package/trail-package.component';


@NgModule({
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    AppComponent,
    AddproductComponent,
    MainComponent,
    ProductListComponent,
    PaymentComponent,
    AddpackageComponent,
    PackageListComponent,
    AddvendorComponent,
    VendorListComponent,
    AddrolesComponent,
    RolesListComponent,
    AddSubAdminComponent,
    SubAdminListComponent,
    CustomerListComponent,
    CustomerdetailComponent,
    TransactionDetailsComponent,
    TransactionListComponent,
    AccountloginComponentComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    EditAccountComponent,
    ValidatepasswordComponent,
    AddenterpriseComponent,
    ListenterpriseComponent,
    EtrCustomerListComponent,
    EtrCustomerdetailComponent,
    ListinvoicesComponent,
    AddinvoiceComponent,
    TrailPackageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [
    DatePipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiDataService } from 'src/app/api-data.service';
import { UtilityService } from 'src/app/utility.service';
import swal from 'sweetalert';
import { get } from 'scriptjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  section = this.utility.apiData.userPurchases;
  isLoadingData = false;
  isApplyingCoupon = false;
  object: any;
  viewprice = 0.00;
  viewplan: string;
  taxRate = 13;
  discount = 10;

  constructor(private router: Router, private route: ActivatedRoute, private utility: UtilityService, private dataService: ApiDataService, private http: HttpClient) { }
  async ngOnInit() {
    this.isLoadingData = true;
    this.route.paramMap.subscribe(params => {
      if (!params.get('id') || params.get('id') == "") {
        swal("invalid PackageID and type");
        // this.router.navigate(['/dashboard/account']);
        return;
      }
      if (params.get('addonID')) {
        this.object.isAddOn = true;
        this.object.addOnId = params.get('addonID');
      }
      let packageId = params.get('id');
      let currency = "USD";
      let viewplan = "";
      let viewprice = 0;
      let service = this.dataService;
      let object: any;
      let section = this.section;

      this.object = this.section.object;
      this.object.email = 'salman1@dentallive.com';
      this.object.accountID = '06ba9a21-dfa0-470f-bd2a-bfda8a7e38fc';
      this.object.modeOfPayment = 'PayPal';

      this.dataService.getData("https://oms5sh4336.execute-api.us-west-2.amazonaws.com/default/packages", packageId)
        .subscribe(Response => {
          if (!Response) {
            swal("invalid PackageID,PackageID dosent Exist");
            return;
            //redirect
          }

          let packageDetail = Response;
          this.object.packageID = packageDetail['packageID'];

          if (this.object.isAddOn) {
            if (!this.object.addOnId) {
              swal('Invalid Add-On');
              //redirect
              return;
            }
            let isExist = false;
            for (let addon in packageDetail['addOnList']) {
              if (addon['addonID'] == this.object.addOnId) {
                isExist = true;
                this.viewplan = packageDetail['addonName'];
                this.viewprice = addon['price'];
                break;
              }
            }
            if (!isExist) {
              swal('Invalid Add-On');
              //redirect
              return;
            }
          } else {
            this.viewplan = packageDetail['packageName'];
            this.viewprice = packageDetail['packagePrice'];
          }

          viewplan = this.viewplan;
          viewprice = this.viewprice;
          object = this.object;

          get('https://www.paypal.com/sdk/js?client-id=AflmBnROardkIoE9SUvSO0apG8hDCfKS9HnAoxMZ2gQwedBpp5ntn0ri0dvfPNRPFNHkiOv1KyNakJz3&currency=' + currency, () => {
            //@ts-ignore
            paypal.Buttons({
              style: {
                shape: 'rect',
                color: 'blue',
                layout: 'vertical',
                label: 'pay',
              },

              createOrder: function (data, actions) {
                let total = viewprice + ((13 / 100) * viewprice);
                let tax = ((13 / 100) * viewprice);
                //refetch package details and couopn code and then send that -- (future)
                return actions.order.create({
                  purchase_units: [{
                    "description": viewplan,
                    "amount": {
                      "currency_code": currency,
                      "value": total,
                      "breakdown": {
                        "item_total": {
                          "currency_code": currency,
                          "value": viewprice
                        },
                        "shipping": {
                          "currency_code": currency,
                          "value": 0
                        },
                        "tax_total": {
                          "currency_code": currency,
                          "value": tax
                        }
                      }
                    }
                  }]
                });
              },

              onApprove: function (data, actions) {
                swal("Processing Payment!", "please wait...", "info", {
                  buttons: [false, false],
                  closeOnClickOutside: false,
                });
                return actions.order.capture().then(function (details) {
                  saveDetails(details);
                });
              },

              onError: function (err) {
                console.log(err);
                swal("unable to process payment,please try again");
              }

            }).render('#paypal-button-container');
          });
        }, error => {
          swal("invalid PackageID,PackageID dosent Exist");
        }, () => {
          console.log('Loading complete');
          this.isLoadingData = false;
        })

      function saveDetails(details) {
        object.payPal = details;
        service.postData(section.ApiUrl, JSON.stringify(object))
          .subscribe(Response => {
            swal("Payment Made Succesfully");
            //redirect
          }, error => {
            swal("Saving Data Failed");
            //redirect
          })
      }
    });
  }

  applyCoupon(coupon) {
    this.isApplyingCoupon = true

    this.http.get(this.utility.apiData.accountUsage.ApiUrl + `?type=coupon&couponcode=${coupon}`)
      .subscribe(Response => {
        if (Response && Response['discount']) {
          this.discount = Response['discount'];
          this.viewprice = this.viewprice - ((Response['discount'] / 100) * this.viewprice);
        }
        else swal("Invalid Coupon Code");
      }, error => {
        swal("Invalid Coupon Code");
        // @ts-ignore
      }, complete => {
        this.isApplyingCoupon = false;
      })
  }
}

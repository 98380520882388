import { ListData, UtilityService } from './../../utility.service';
import { ApiDataService } from './../../api-data.service';
import { Component, OnInit } from '@angular/core';
import sortArray from 'sort-array';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit, ListData {

  constructor(private dataService: ApiDataService, private utility: UtilityService) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.products;

  loadData() {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    this.objectList = this.pristineData.filter((product) => {
      if (product.productName)
        return (
          product.productName.toLowerCase().includes(filterValue)
        );
    });
  }

  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage
  }
}


import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiDataService } from "src/app/api-data.service";
import { ListData, UtilityService } from "src/app/utility.service";
import swal from "sweetalert";
import { Location } from '@angular/common';
import sortArray from 'sort-array';

@Component({
  selector: "app-etrcustomer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class EtrCustomerListComponent implements OnInit, ListData {
  constructor(
    private route: ActivatedRoute,
    private dataService: ApiDataService,
    private utility: UtilityService,
    private location: Location
  ) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.etrUsers;
  user = JSON.parse(sessionStorage.getItem("etrUser"));

  loadData() {

  }
  loadDatawithId(id) {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl + `?etrid=${id}`)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.hasData();
  }

  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("id") && params.get("id") != "") {
        this.isLoadingData = true;
        this.loadDatawithId(params.get("id"));
      } else {
        swal("No data exists");
        this.location.back();
      }
    });
  }

  filterData(filterValue: string) {
    this.objectList = this.pristineData.filter((accounts) => {
      if (accounts.accountfirstName || accounts.accountlastName || accounts.emailAddress)
        return (
          accounts.accountfirstName.toLowerCase().includes(filterValue) || accounts.accountlastName.toLowerCase().includes(filterValue) || accounts.emailAddress.toLowerCase().includes(filterValue)
        );
    });
  }

  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }

  isActive(customer) {
    if (!('status' in customer)) return true;
    return customer.status;
  }
}




import { UtilityService } from "./../../utility.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';
@Component({
  selector: 'app-trail-package',
  templateUrl: './trail-package.component.html',
  styleUrls: ['./trail-package.component.css']
})
export class TrailPackageComponent implements OnInit {
  constructor(
    public utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location,
  ) { }

  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  object: any = {
    usersAllocated: 0,
    storageAllocated: 0,
  }

  @ViewChild("mainForm", { static: false }) mainForm: NgForm;

  ngOnInit() {
    this.resetForm();
    this.loadData();
  }

  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = {
      usersAllocated: 0,
      storageAllocated: 0,
    }
  }

  async loadData() {
    //Get Form Data via API
    try {
      this.isLoadingData = true;
      let result = await this.dataService.getallData('https://oms5sh4336.execute-api.us-west-2.amazonaws.com/default/trail').toPromise();
      if (result) this.object = result;
      this.isLoadingData = false;
    } catch (error) {
      console.log(error)
      this.isLoadingData = false;
    }
  }

  uploadFormData() {
    //post request here,both add & update are sent as post
    console.log(this.object);
    this.dataService
      .postData('https://oms5sh4336.execute-api.us-west-2.amazonaws.com/default/trail', JSON.stringify(this.object))
      .subscribe((Response) => {
        swal("Trail Package updated succesfully")
        this.location.back();
      }, (error) => {
        error.status == "406" ? swal("Cannot update,Data Exists for this package") : swal("Failed to process request,please try again");
        this.isUploadingData = false;
      });
  }


  onSubmit() {
    if (this.mainForm.invalid) {
      this.mainForm.form.markAllAsTouched();
      swal("Please enter values in the highlighted fields");
      return false;
    }
    this.isUploadingData = true;
    console.log(this.object);
    this.uploadFormData();
  }
}

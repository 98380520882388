import { ListData, UtilityService } from './../../utility.service';
import { ApiDataService } from './../../api-data.service';
import { Component, OnInit } from '@angular/core';
import sortArray from 'sort-array';

@Component({
  selector: "app-package-list",
  templateUrl: "./package-list.component.html",
  styleUrls: ["./package-list.component.css"],
})
export class PackageListComponent implements OnInit, ListData {

  constructor(private dataService: ApiDataService, private utility: UtilityService) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.packages;

  loadData() {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    if (!filterValue) {
      this.objectList = this.pristineData;
      return;
    }
    this.objectList = this.pristineData.filter((pkg) => {
      if (pkg.packageName)
        return (
          pkg.packageName.toLowerCase().includes(filterValue)
        );
    });
  }

  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  getCount() {
    return parseInt(this.itemsPerPage.toString()) + parseInt(this.pageNumber.toString());
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage
  }
}

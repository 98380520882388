import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import swal from "sweetalert";
import { ApiDataService } from "src/app/api-data.service";
import { Location } from '@angular/common';
import { ListData, UtilityService } from "src/app/utility.service";
import sortArray from 'sort-array';
@Component({
  selector: 'app-addinvoice',
  templateUrl: './addinvoice.component.html',
  styleUrls: ['./addinvoice.component.css']
})
export class AddinvoiceComponent implements OnInit, ListData {
  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit
  // CamelCase for naming anything
  // select this appropriately
  section = this.utility.apiData.invoice;
  object: any;
  isUploadingData = false;
  isLoadingData = false;
  constructor(
    private route: ActivatedRoute,
    private utility: UtilityService,
    private dataService: ApiDataService,
    private location: Location
  ) { }
  objectList: any;
  pristineData: any;
  filterData(filterValue: string) {

  }
  @ViewChild("mainForm", { static: false }) mainForm: NgForm;
  ngOnInit() {
    this.resetForm();
    this.hasData();
  }
  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    // change
    this.object = {}
  }
  loadData() { }
  itemsPerPage = 10;
  pageNumber = 0;
  loadDataEtr(etrid, id) {
    //Get Form Data via API
    this.dataService.getallData(this.section.ApiUrl + `?etrid=${etrid}&id=${id}`).subscribe(
      (Response) => {
        console.log(Response);
        this.object = Response;
        this.isLoadingData = false;
      },
      (error) => {
        // swal("No data exists");
        // this.location.back();
      });
  }
  hasData() {
    this.route.paramMap.subscribe((params) => {
      if (params.get("etrid") && params.get("etrid") != "" && params.get("id") && params.get("id") != "") {
        this.isLoadingData = true;
        this.loadDataEtr(params.get("etrid"), params.get("id"));
      } else {
        swal('No Data Exists');
        this.location.back();
      }
    });
  }
  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    if (sortValue.includes('.'))
      sortArray(this.object.invoiceDetails, {
        by: 'value', order: this.sortBoolean[sortValue] ? 'desc' : 'asc', computed: {
          value: row => {
            let rowSelect;
            for (let ar of sortValue.split("."))
              rowSelect ? rowSelect = rowSelect[ar] : rowSelect = row[ar];
            return rowSelect;
          }
        }
      });
    else
      sortArray(this.object.invoiceDetails, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }
  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }
  getCount() {
    return parseInt(this.itemsPerPage.toString()) + parseInt(this.pageNumber.toString());
  }
  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }
}

import { Component, OnInit } from '@angular/core';
import { UtilityService } from '../utility.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private utility: UtilityService) { }
  permissionList = [];
  user = {}

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem("adminUser"));
    if (this.user['email'] == "admin@dentallive.com")
      this.permissionList = ['customer', 'package', 'vendor', 'finance', 'enterprise', 'users'];
    else
      this.permissionList = this.user['permission']['permissionList'];

  }

}

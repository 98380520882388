import { TrailPackageComponent } from './packages/trail-package/trail-package.component';
import { PaymentComponent } from './useraccounts/payment/payment.component';
import { ProductListComponent } from './products/product-list/product-list.component';
import { MainComponent } from './main/main.component';
import { AddproductComponent } from './products/addproduct/addproduct.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PackageListComponent } from './packages/package-list/package-list.component';
import { AddpackageComponent } from './packages/addpackage/addpackage.component';
import { VendorListComponent } from './vendors/vendor-list/vendor-list.component';
import { AddvendorComponent } from './vendors/addvendor/addvendor.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { AddrolesComponent } from './roles/addroles/addroles.component';
import { SubAdminListComponent } from './sub-admin/sub-admin-list/sub-admin-list.component';
import { AddSubAdminComponent } from './sub-admin/add-sub-admin/add-sub-admin.component';
import { CustomerListComponent } from './customer/customer-list/customer-list.component';
import { CustomerdetailComponent } from './customer/customerdetail/customerdetail.component';
import { TransactionListComponent } from './finance/transaction-list/transaction-list.component';
import { TransactionDetailsComponent } from './finance/transaction-details/transaction-details.component';
import { AccountloginComponentComponent } from './accountlogin-component/accountlogin-component.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { AuthGuardServiceService } from './auth-guard-service.service';
import { EditAccountComponent } from './account/edit-account/edit-account.component';
import { ListenterpriseComponent } from './enteprie/listenterprise/listenterprise.component';
import { AddenterpriseComponent } from './enteprie/addenterprise/addenterprise.component';
import { ValidatepasswordComponent } from './validatepassword/validatepassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { EtrCustomerListComponent } from './enteprie/customer/customer-list/customer-list.component';
import { EtrCustomerdetailComponent } from './enteprie/customer/customerdetail/customerdetail.component';
import { ListinvoicesComponent } from './enteprie/invoices/listinvoices/listinvoices.component';
import { AddinvoiceComponent } from './enteprie/invoices/addinvoice/addinvoice.component';

const routes: Routes = [
  {
    path: 'admin',
    component: MainComponent,
    canActivate: [AuthGuardServiceService],
    children: [
      // products
      { path: 'products', component: ProductListComponent },
      { path: 'products/product', component: AddproductComponent },
      { path: 'products/product/:id', component: AddproductComponent },

      // packages
      { path: 'packages', component: PackageListComponent },
      { path: 'packages/trail', component: TrailPackageComponent },
      { path: 'packages/package', component: AddpackageComponent },
      { path: 'packages/package/:id', component: AddpackageComponent },

      // vendors
      { path: 'vendors', component: VendorListComponent },
      { path: 'vendors/vendor', component: AddvendorComponent },
      { path: 'vendors/vendor/:id', component: AddvendorComponent },

      // enterprises
      { path: 'enterprises', component: ListenterpriseComponent },
      { path: 'enterprises/enterprise', component: AddenterpriseComponent },
      { path: 'enterprises/enterprise/:id', component: AddenterpriseComponent },

      { path: 'enterprises/customers/:id', component: EtrCustomerListComponent },
      { path: 'enterprises/customers/customer/:id', component: EtrCustomerdetailComponent },

      { path: 'etrInvoices', component: ListinvoicesComponent },
      { path: 'etrInvoices/invoice/:etrid/:id', component: AddinvoiceComponent },

      // roles
      { path: 'roles', component: RolesListComponent },
      { path: 'roles/role', component: AddrolesComponent },
      { path: 'roles/role/:id', component: AddrolesComponent },

      // users
      { path: 'users', component: SubAdminListComponent },
      { path: 'users/user', component: AddSubAdminComponent },
      { path: 'users/user/:id', component: AddSubAdminComponent },

      // customers
      { path: 'customers', component: CustomerListComponent },
      { path: 'customers/customer/:id', component: CustomerdetailComponent },

      // transaction
      { path: 'transactions', component: TransactionListComponent },
      { path: 'transactions/transaction/:email/:id', component: TransactionDetailsComponent },

      // Edit Account
      { path: 'account', component: EditAccountComponent },
    ]
  },
  //login
  { path: '', component: AccountloginComponentComponent },
  { path: 'login', component: AccountloginComponentComponent },
  { path: 'forget', component: ForgetpasswordComponent },
  { path: 'reset/:email/:randNo', component: ResetpasswordComponent },
  { path: 'validate/:id/:random', component: ValidatepasswordComponent },
  {
    path: 'payment/:id',
    component: PaymentComponent,
  },
  {
    path: 'payment/:id/:addonID',
    component: PaymentComponent,
  }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

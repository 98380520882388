import { ListData, UtilityService } from "./../../utility.service";
import { ApiDataService } from "./../../api-data.service";
import { Component, OnInit } from "@angular/core";
import sortArray from 'sort-array';

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class CustomerListComponent implements OnInit, ListData {
  constructor(
    private dataService: ApiDataService,
    private utility: UtilityService
  ) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.accounts;
  enterpriseList: any = [];
  locationList: any = [];

  fetchData() {
    //Get Form Data via API
    this.dataService.getallData(this.utility.apiData.enterprise.ApiUrl)
      .subscribe(Response => {
        this.enterpriseList = Response;
        console.log(this.enterpriseList);
      }, error => {
        console.log(error);
      });
  }

  filterLOC(etrID, etrLocID) {
    if (!etrID && !etrLocID) {
      this.objectList = this.pristineData;
      return;
    }
    this.objectList = this.pristineData.filter((accounts) => {
      if (etrLocID)
        return (
          accounts.etrID && accounts.etrLocID && accounts.etrLocID == etrLocID
        );
      else
        return (
          accounts.etrID && accounts.etrID == etrID
        );
    });
  }

  fetchLocationData(val) {
    this.locationList = [];
    if (!val) {
      this.objectList = this.pristineData;
      return;
    }
    this.filterLOC(val, null);
    //Get Form Data via API
    this.dataService.getallData(this.utility.apiData.location.ApiUrl + `?etrid=${val}`)
      .subscribe(Response => {
        this.locationList = Response;
      }, error => {
        console.log(error);
      });
  }

  loadData() {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
        this.fetchData()
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    if (!filterValue) {
      this.objectList = this.pristineData;
      return;
    }
    this.objectList = this.pristineData.filter((accounts) => {
      if (accounts.accountfirstName || accounts.accountlastName || accounts.emailAddress)
        return (
          accounts.accountfirstName.toLowerCase().includes(filterValue) || accounts.accountlastName.toLowerCase().includes(filterValue) || accounts.emailAddress.toLowerCase().includes(filterValue)
        );
    });
  }

  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  getCount() {
    return parseInt(this.itemsPerPage.toString()) + parseInt(this.pageNumber.toString());
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }

  isActive(customer) {
    if (!('status' in customer)) return true;
    return customer.status;
  }
}




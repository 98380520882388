import { ListData, UtilityService } from "./../../utility.service";
import { ApiDataService } from "./../../api-data.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-vendor-list",
  templateUrl: "./vendor-list.component.html",
  styleUrls: ["./vendor-list.component.css"],
})
export class VendorListComponent implements OnInit, ListData {
  constructor(
    private dataService: ApiDataService,
    private utility: UtilityService
  ) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.vendors;

  loadData() {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    if (!filterValue) {
      this.objectList = this.pristineData;
      return;
    }
    this.objectList = this.pristineData.filter((vendor) => {
      if (vendor.firstName || vendor.lastName || vendor.email)
        return (
          vendor.firstName.toLowerCase().includes(filterValue) || vendor.lastName.toLowerCase().includes(filterValue) || vendor.email.toLowerCase().includes(filterValue)
        );
    });
  }

  sortData(sortValue: string) {
    this.objectList = this.objectList.sort((a, b) => (a.sortValue > b.sortValue) ? 1 : -1)
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  getCount() {
    return parseInt(this.itemsPerPage.toString()) + parseInt(this.pageNumber.toString());
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }
}

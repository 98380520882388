import { ListData, UtilityService } from "./../../utility.service";
import { ApiDataService } from "./../../api-data.service";
import { Component, OnInit } from "@angular/core";
import sortArray from 'sort-array';


@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.css']
})
export class TransactionListComponent implements OnInit, ListData {
  constructor(
    private dataService: ApiDataService,
    private utility: UtilityService
  ) { }

  isLoadingData = false;
  objectList: any;
  pristineData: any;
  itemsPerPage = 10;
  pageNumber = 0;
  // select this appropriately
  object = this.utility.apiData.purchases;

  loadData() {
    //Get Form Data via API
    this.dataService.getallData(this.object.ApiUrl)
      .subscribe(Response => {
        this.objectList = Response;
        this.pristineData = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = false;
      })
  }

  ngOnInit() {
    this.isLoadingData = true;
    this.objectList = [];
    this.loadData();
  }

  filterData(filterValue: string) {
    if (!filterValue) {
      this.objectList = this.pristineData;
      return;
    }
    this.objectList = this.pristineData.filter((transaction) => {
      if (transaction.dependencies.customer.accountfirstName || transaction.dependencies.customer.accountlastName || transaction.dependencies.customer.emailAddress)
        return (
          transaction.dependencies.customer.accountfirstName.toLowerCase().includes(filterValue) || transaction.dependencies.customer.accountlastName.toLowerCase().includes(filterValue) || transaction.dependencies.customer.emailAddress.toLowerCase().includes(filterValue)
        );
    });
  }


  sortBoolean: any = {};
  sortData(sortValue) {
    this.sortBoolean[sortValue] = this.sortBoolean[sortValue] ? false : true;
    if (sortValue.includes('.'))
      sortArray(this.objectList, {
        by: 'value', order: this.sortBoolean[sortValue] ? 'desc' : 'asc', computed: {
          value: row => {
            let rowSelect;
            for (let ar of sortValue.split("."))
              rowSelect ? rowSelect = rowSelect[ar] : rowSelect = row[ar];
            return rowSelect;
          }
        }
      });
    else
      sortArray(this.objectList, { by: sortValue, order: this.sortBoolean[sortValue] ? 'desc' : 'asc' });
  }

  counter(items: number) {
    return new Array(Math.round(items / this.itemsPerPage));
  }

  getCount() {
    return parseInt(this.itemsPerPage.toString()) + parseInt(this.pageNumber.toString());
  }

  // helper function
  changePage(number: number) {
    this.pageNumber = number * this.itemsPerPage;
  }
}
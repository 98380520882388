import { AddEditData, UtilityService } from './../../utility.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert';
import { ApiDataService } from 'src/app/api-data.service';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})

export class AddproductComponent implements OnInit, AddEditData {
  constructor(private route: ActivatedRoute, private utility: UtilityService, private dataService: ApiDataService) { }

  // step 1 : Assign section variable correctly
  // Step 2 : If EditMode validate and Bind Html input to this object using template driven forms (2-way Binding ([ngModel]))
  // Step 3 : If any Binary Data is uploaded add it to Binary Array
  // Step 4 : on Submit validate schema to be sent-->if valid upload Binary Array to S3 ,upon succes upload Form Data
  // Step 6 : Reset State of form after submit 
  // CamelCase for naming anything

  // select this appropriately
  section = this.utility.apiData.products;
  object: any;
  isEditMode = false;
  isUploadingData = false;
  isLoadingData = false;
  binaryFiles = [];

  @ViewChild('mainForm', { static: false }) mainForm: NgForm;

  ngOnInit() {
    this.resetForm();
    this.hasData();
  }

  resetForm() {
    this.isUploadingData = false;
    this.isLoadingData = false;
    this.isEditMode = false;
    // change
    this.object = this.section.object;
  }

  loadData(id) {
    //Get Form Data via API
    this.dataService.getData(this.section.ApiUrl, id)
      .subscribe(Response => {
        console.log(Response);
        if (!this.utility.dovValidateSchema.validate(Response, this.section.schema).valid) {
          swal("No data exists")
            .then((value) => {
              return false;
            });
        }
        this.object = Response;
      }, error => {
        console.log(error);
      }, () => {
        this.isLoadingData = true;
      })
  }

  hasData() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id') && params.get('id') != "") {
        this.isLoadingData = true;
        this.isEditMode = true;
        this.loadData(params.get('id'));
      }
    });
  }

  uploadBinaryData() {
    this.object.imageSrc = "";//only in cases where there is singular binding
    let requests = this.binaryFiles.map((object) => {
      return this.utility.uploadBinaryData(object['name'], object['binaryData'], this.section.bucket);
    });
    Promise.all(requests)
      .then((responses) => {
        this.object.imageSrc = responses[0]['name'];
        this.uploadFormData();
      }).catch((error) => {
        console.log(error);
        this.isUploadingData = false;
      })
  }

  uploadFormData() {
    //post request here,both add & update are sent as post
    this.dataService.postData(this.section.ApiUrl, JSON.stringify(this.object))
      .subscribe(Response => {
        console.log(Response);
      }, error => {
        console.log(error);
      }, () => {
        this.resetForm();
      })
  }

  loadBinaryFile(event) {
    if (event.target.files.length > 0) {
      //reset binaryFiles array to this image --> S3 allows to directly upload file object or Blob data,for simplicity here file object is used
      let name = uuidv4();
      this.binaryFiles = [{ 'name': name, 'binaryData': event.target.files[0] }];

      //display selected file in image tag
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);//initiates converting file to blob
      reader.onload = e => this.object.imageSrc = reader.result; // call back after file is converted to Blob
    }
  }

  onSubmit() {
    this.isUploadingData = true;
    if (this.mainForm.invalid) {
      this.mainForm.form.markAllAsTouched();
      return false;
    }
    if (this.binaryFiles.length > 0) {
      this.uploadBinaryData();
    } else {
      this.uploadFormData();
    }
  }

  // special functions
  addFeature(name, description) {
    this.object.features.push({ 'featureName': name, 'description': description, 'dateUpdated': new Date().getTime() });
  }
}
